@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #1a1a1a;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-normal {
  font-weight: 500 !important;
}

.text-32 {
  font-size: 32px;
}

.text-14 {
  font-size: 14px;
}

.bg-dark-purple {
  background-color: #6f42c1;
}

.color-purple {
  color: #8f6ecd;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-primary {
  background-color: #6f42c1 !important;
  border: none !important;
}

.btn-outline-primary {
  border-color: #6f42c1 !important;
  color: #6f42c1 !important;
  transition: 0.3s ease-in-out !important;
}

.btn-outline-primary:hover {
  background-color: #6f42c1 !important;
  color: #fff !important;
}

.disabled {
  background-color: #999 !important;
  border: none !important;
}

.blackCheckMark {
  fill: #1a1a1a;
}

.purpleCheckMark {
  fill: #8f6ecd;
  width: 26px !important;
  height: 26px !important;
}

.customSpinner {
  color: #8f6ecd;
  width: 26px !important;
  height: 26px !important;
}
