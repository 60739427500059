.treeCodeGenerator {
  position: relative;
  font-size: .85rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left!important;
}

.treeCodeGenerator li {
  list-style-type: none;
}

.active-custom-elem {
  font-weight: 800;
}